h1 a {
	text-decoration: none;
}

body {
	font-family: 'Lucida Console', Monaco, monospace;
	text-align: center;
	margin: 0;
}

main {
	margin: 50px auto 50px;
}

caption {
	padding-bottom: 10px;
}

table {
	border-collapse: collapse;
	margin: auto;
	background: white;
}

a {
	color: blue;
}

a:hover {
	color: red;
}