td {
    width: 200px;
    height: 200px;
    transition: all 0.5s;
}

#new {
    float: left;
    transition: all 0.5s;
}

#level {
    float: right;
    transition: all 0.2s;
}