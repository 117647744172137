#new {
    float: left;
}

#score {
    float: right;
    transition: all 0.2s;
}

#field td {
    border: 1px solid #cccccc;
    width: 160px;
    height: 160px;
    transition: all 0.4s;
    font-size: 70px;
}

.bottom {
    margin-top: 10px;
    width: 100px;
    font-size: 16px;
}